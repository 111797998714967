/* BASE */
*,
html {
  font-family: "Work Sans", sans-serif;
  box-sizing: border-box;
  transition: all 0.15s ease-out;
}

button {
  cursor: pointer;
}

body {
  background: #9b5de5;
}

.App {
  display: flex;
  align-items: center;
}

.App h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
}

.App h2 {
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #fafafa;
  margin-top: 0px;
  margin-bottom: 20px;
}

.App p {
  font-weight: 400;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.hidden {
  display: none;
}

.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  align-items: center;
  background: #fff;
}

form {
  width: 90%;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  max-width: 600px;
  padding: 40px 20px;
}

form label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #293264;
  margin: 3px 10px;
}

.notes-label {
  width: 300px;
  margin-left: 11px;
  margin-top: 30px;
}

#item-holder {
  width: 100%;
}

form label input,
form textarea {
  margin-left: -2px;
  margin-top: 4px;
  width: 100%;
  height: 36px;
  background: #ffffff;
  border: 1px solid rgba(41, 50, 100, 0.25);
  border-radius: 7px;
  font-size: 16px;
  padding-left: 8px;
}

form textarea {
  width: 300px;
  height: 60px;
  margin: 5px;
}

form label input:focus,
form textarea:focus {
  outline: none;
  border: 2px solid #9b5de5;
}

#share-btn {
  border: none;
  width: 186px;
  height: 40px;
  background: #3a86ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  font-size: 18px;
  color: white;
  margin: 30px 5px 10px;
}

#share-btn:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.delete-btn {
  background: none;
  border: none;
  color: #ff5252;
  font-size: 22px;
  margin-top: 25px;
}

.item-holder:not(:first-of-type) .delete-btn {
  margin-top: 8px;
}

.transparent {
  color: transparent;
}

/* SHARE MODE */

.share-mode {
  width: 95%;
  max-width: 350px;
}

@keyframes appear {
  0% {
    background: transparent;
    color: transparent;
  }

  10% {
    background: transparent;
    color: transparent;
  }

  100% {}
}

form .copied {
  color: #2e7d32;
  background: #eee;
  border-radius: 4px;
  padding: 10px;
  font-weight: 700;
  animation-name: appear;
  animation-duration: 0.5s;
}

/* START AND EDIT BUTTONS */
.edit-start-zone {
  margin: 20px 10px;
}

.edit-start-zone button {
  border: none;
  background: #eee;
  color: #333;
  border-radius: 4px;
  font-size: 14px;
  margin: 0px 10px;
  animation-name: appear;
  animation-duration: 0.5s;
  padding: 8px 10px;
}

/* LETS GET RESPONSIVE */
@media only screen and (max-width: 480px) {
  form {
    padding: 20px 5px;
  }

  form label {
    margin-right: 0px;
  }

  .delete-btn {
    padding: 0px 5px;
  }

  footer {
    font-size: 12px;
  }
}

/* FOOTER */

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  background: #e75f7a88;
  color: white;
}

footer ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
}

footer ul li {
  margin: 0px 10px;
}

footer ul li a {
  text-decoration: none;
}

/* DISCOVER PAGE */

.discover h1 {
  margin-bottom: 30px;
}

.recipes-container {
  display: grid;
  width: 100%;
  max-width: 800px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 20px;
  padding: 10px;
}

.featuredRecipe {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.featuredRecipe svg {
  height: 50px;
  margin: 40px 15px;
}

.featuredRecipe h3 {
  font-size: 1.5rem;
  font-weight: 300;
  color: #222;
}

.featuredRecipe a {
  text-decoration: none;
}

.featuredRecipe.card {
  padding: 5px;
  border-radius: 20px;
}


/* NAV */
nav {
  margin-top: 20px;
}

nav a {
  margin: 10px;
  background: #fafafa;
  border-radius: 10px;
  padding: 5px;
  text-decoration: none;
}

nav a.active-nav {
  font-weight: 700;
  background: #FFBE0B;
  color: black;
  box-shadow: 0px 2px 19px #0000006F;
}


nav a.inactive-nav {
  color: #777;
  font-weight: 300;
}